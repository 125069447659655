import React, { useState, useEffect } from 'react';
import CreatePromptForm from '../../../Components/CreatePromptForm';
import axios from 'axios';
import Helpers from '../../../Config/Helpers';

import '../Screens/Loader.css';

const PromptsPage = () => {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPrompt, setCurrentPrompt] = useState(null);

  useEffect(() => {
    fetchPrompts();
  }, []);

  const fetchPrompts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Helpers.apiUrl}products/getPrompt`, Helpers.authHeaders);
      setPrompts(response.data);
      Helpers.toast("success", 'Prompts fetched successfully!');
    } catch (error) {
      console.error('Error fetching prompts:', error);
      setError('Failed to fetch prompts');
    } finally {
      setLoading(false);
    }
  };

  const createOrUpdatePrompt = async (promptText) => {
    if (!promptText) {
      Helpers.toast("error", 'Prompt Text Required!');
      return;
    }

    try {
      const payload = currentPrompt ? { prompt: promptText, id: currentPrompt.id } : { prompt: promptText };

      const response = await axios.post(`${Helpers.apiUrl}products/prompts/storeOrUpdate`, payload, Helpers.authHeaders);
      Helpers.toast("success", currentPrompt ? 'Prompt Updated Successfully!' : 'Prompt Created Successfully!');
      fetchPrompts();
      setCurrentPrompt(null); // Reset current prompt
    } catch (error) {
      console.error('Error creating or updating prompt:', error);
      Helpers.toast("error", 'Error creating or updating prompt.');
    }
  };

  const handleEditPrompt = (prompt) => {
    setCurrentPrompt(prompt);
  };
  const renderPrompts = () => {
    // If prompts is an array, render using map
    if (Array.isArray(prompts)) {
      return prompts.map((promptItem) => (
        <div  key={promptItem.id}>
          <p >{promptItem.prompt}</p>
          <button className="btn btn-secondary btn-sm" onClick={() => handleEditPrompt(promptItem)}>
            Edit
          </button>
        </div>
      ));
    } 
  
    // If prompts is a single object, render it directly
    if (prompts && typeof prompts === 'object') {
      return (
        <div>
          <p>{prompts.prompt}</p>
          <button className="btn btn-secondary btn-sm" onClick={() => handleEditPrompt(prompts)}>
            Edit
          </button>
        </div>
      );
    }
  
    return <p>No prompts available.</p>;
  };

  return (
    <div className="nk-content">
      <div className="container-xl">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="card mt-4">
              <div className="card-header">
                <h4 className="card-title">Prompts</h4>
                <CreatePromptForm onSubmit={createOrUpdatePrompt} initialPrompt={currentPrompt ? currentPrompt.prompt : ''} />
              </div>
              {loading ? (
                <p>Loading prompts...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <div className='m-4'>
                  <h5>Latest Prompts:</h5>
                  {renderPrompts()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptsPage;
