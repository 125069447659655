import { useState } from "react";
import Helpers from "../../../Config/Helpers";
import useTitle from "../../../Hooks/useTitle";
import PageLoader from "../../../Components/Loader/PageLoader";

const UserDashboard = () => {
    useTitle("Dashboard");
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    {isLoading ? (
                        <PageLoader />
                    ) : (
                        <div className="nk-content-body">
                            <div className="nk-block-head nk-page-head">
                                <div className="nk-block-head-between">
                                    <div className="nk-block-head-content">
                                        <h2 className="display-6">Welcome {Helpers.authUser.name}!</h2>
                                    </div>
                                </div>
                            </div>
                            {/* Content can be placed here */}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserDashboard;
