import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Helpers from '../../../Config/Helpers';
import '../Screens/Loader.css';
import ReactPaginate from 'react-paginate';
const pageSize = 10;

const ProductsComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [productID, setProductID] = useState(null);
    const [isGeneratingIndividual, setIsGeneratingIndividual] = useState(false);
    const [isGeneratingSpecific, setIsGeneratingSpecific] = useState({});
    const [paginatedProducts, setPaginatedProducts] = useState([]);
    const [selectedSearches, setSelectedSearches] = useState(["version", "synonym", "acronym"]);
    const [showModal, setShowModal] = useState(false);
    const [currentKeywords, setCurrentKeywords] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [showButton, setShowButton] = useState(false);

    const [isGeneratingAll, setIsGeneratingAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('view');
    const [error, setError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [forceState, setForceState] = useState(0);
    const [searchedObject, setSearchedObject] = useState({});
    const [generatedKeywordsCount, setGeneratedKeywordsCount] = useState(0);
    const [openedAccordionIndex, setOpenedAccordionIndex] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [isExporting, setIsExporting] = useState(false);

    useEffect(() => {
        setForceState(prev => prev + 1)
        // console.log(allProducts);
    }, [allProducts])
    const fetchProducts = async (page) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${Helpers.apiUrl}products/getData?page=${page}`, Helpers.authHeaders);
            const fetchedProducts = response.data || [];
            setAllProducts(fetchedProducts);
            setPaginatedProducts(Helpers.paginate(fetchedProducts, pageSize)[currentPage - 1] || []);
            setTotalPages(Math.ceil(fetchedProducts.length / pageSize));
            Helpers.toast("success", 'Product Fetch Successfully');
        } catch (error) {
            setError('Error fetching products: ' + error.message);
            console.error('Error fetching products:', error);
        } finally {
            setIsLoading(false);
        }
    };


    
    const handleProductSelection = event => {
        const value = event.target.value;
        const checked = event.target.checked; // This is true if the checkbox is checked

        setSelectedProduct(prevSelectedProduct => {
            if (checked) {
                return prevSelectedProduct.includes(value) ? prevSelectedProduct : [...prevSelectedProduct, value];
            } else {
                // If the checkbox is unchecked, remove the value from the array
                return prevSelectedProduct.filter(item => item !== value);
            }
        });
    };
    const isAnyProductGenerating = selectedProduct.some(productId => isGeneratingSpecific[productId]);
    const buttonText = isAnyProductGenerating ? 'Exporting...' : 'Export Selected Keywords';



    const handleSearchMultipleSelected = async () => {
        for (const productId of selectedProduct) {
            // console.log(productId, 'ye new product id h');
            let product = allProducts.find(item => item.id.toString() === productId.toString());
            if (product) {
                const updatedSearchedObject = await sortMultipleSelected(product);
                await generateKeywordsForSpecificProduct(product.id, updatedSearchedObject);
                console.log("Logging Again: ", allProducts)
            } else {
                console.log(`Product with ID ${productId} not found.`);
            }
        }
    }

    const sortMultipleSelected = async (product) => {
        let updatedSearchedObject = { ...searchedObject };
        selectedSearches.forEach(element => {
            updatedSearchedObject[element] = product[element];
        });

        return updatedSearchedObject;
    };
    const handleSelectedCheckBox = event => {
        const value = event.target.value;
    
        setSelectedSearches(currentSelected => {
            // If the current checkbox is already selected, deselect it
            if (currentSelected === value) {
                return '';
            } else {
                // Otherwise, select the new checkbox
                return value;
            }
        });
    };
    
    
  const generateKeywordsForSpecificProduct = async (productId) => {
    setIsGeneratingSpecific(prevState => ({
        ...prevState,
        [productId]: true,
    }));

    const productIndex = allProducts.findIndex(product => product.id === productId);
    if (productIndex === -1) {
        console.error(`Product with ID ${productId} not found.`);
        return;
    }
    setIsGeneratingAll(false);
    const product = allProducts[productIndex];

   try {
        const response = await axios.post(`${Helpers.apiUrl}products/generateKeywords/${productId}`, {
            productContent: product.content,
        }, Helpers.authHeaders);

        // Update the specific product's keyword data
        setAllProducts(prevProducts => prevProducts.map(item => {
            if (item.id === productId) {
                // Check if response.data.keywords is an array before mapping
                const keywordsArray = Array.isArray(response.data.keywords) 
                    ? response.data.keywords.map((keyword, index) => ({
                        id: item.id * 1000 + index, // Example ID generation
                        product_id: item.id,
                        keyword: keyword
                      }))
                    : [{id: item.id * 1000, product_id: item.id, keyword: response.data.keywords}];

                return {
                    ...item,
                    generatedKeyword: response.data.keyword,
                    keywords: keywordsArray
                };
            }
            return item;
        }));
        setGeneratedKeywordsCount(prevCount => prevCount + 1);
        Helpers.toast("success", 'Keyword Generated Successfully!');
    } catch (error) {
        console.error(`Error generating keywords for product ID ${productId}:`, error);
    } finally {
        setIsGeneratingSpecific(prevState => ({
            ...prevState,
            [productId]: false,
        }));
        fetchProducts(currentPage);
    }
};

      
    const handleSearchSelected = (input) => {
      
        let productObject;
        let productId;

        if (typeof input === 'object') {
            productObject = input;
            productId = productObject.id;
        } else {
            productId = parseInt(input, 10);

            productObject = allProducts.find(product => product.id === productId);

            if (!productObject) {
                return;
            }
        }

        let updatedSearchedObject = { ...searchedObject };

        selectedSearches.forEach(element => {
            updatedSearchedObject[element] = productObject[element];
        });

        setSearchedObject(updatedSearchedObject);
        setProductID(productId);
    };

   


    
    

    useEffect(() => {
        // Function to update paginated products
        const updatePagination = () => {
            const paginated = Helpers.paginate(allProducts, pageSize);
            setPaginatedProducts(paginated[currentPage - 1] || []);
            setTotalPages(Math.ceil(allProducts.length / pageSize));
        };
    
        updatePagination();
    }, [allProducts]); // Add pageSize to dependencies if it can change

  
    

    const copyToClipboard = (keyword, index) => {
        navigator.clipboard.writeText(keyword).then(() => {
            Helpers.toast("success", "Keyword copied to clipboard!");

            const copiedKeywordBadge = document.getElementById(`keyword-badge-${index}`);
            if (copiedKeywordBadge) {
                copiedKeywordBadge.classList.add('keyword-copied');
                setTimeout(() => {
                    copiedKeywordBadge.classList.remove('keyword-copied');
                }, 2000);
            }
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }
    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, [showModal]);

    useEffect(() => {
        const fetchAllProducts = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${Helpers.apiUrl}products/getData`, Helpers.authHeaders);
                const fetchedProducts = response.data || [];
                const paginated = Helpers.paginate(fetchedProducts, pageSize);
                setAllProducts(fetchedProducts);
                setTotalPages(Math.ceil(fetchedProducts.length / pageSize));
                setPaginatedProducts(paginated[currentPage - 1] || []);
                Helpers.toast("success", 'Products fetched successfully!');
            } catch (error) {
                setError('Failed to fetch products');
                console.error('Error fetching products:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAllProducts();
    }, [currentPage]);

    useEffect(() => {
        const fetchFilteredProducts = async () => {
            // setIsLoading(true);
            try {
                const response = await axios.get(`${Helpers.apiUrl}products/search`, {
                    ...Helpers.authHeaders,
                    params: { query: searchQuery, page: currentPage, pageSize: pageSize }
                });
    
                // The actual products are in the 'data' key of the response
                const fetchedData = response.data || {};
                const fetchedProducts = fetchedData.data || [];

    
                setAllProducts(fetchedProducts);
                setTotalPages(Math.ceil(fetchedData.total / pageSize));

                setPaginatedProducts(fetchedProducts);
            } catch (error) {
                setError('Failed to fetch products');
                console.error('Error fetching products:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        if (searchQuery) {
            fetchFilteredProducts();
        } else {
            // fetchAllProducts();
        }
    }, [currentPage, searchQuery]);
    
       
 
  
    const handleViewKeywords = (keywordObjects) => {
        // console.log("Received keyword object:", keywordObjects);
    
        try {
            const parsedKeywords = keywordObjects.map(kw => {
                if (!kw || !kw.keyword) {
                    console.error("Keyword object or keyword property is undefined.");
                    return { title: "Unknown", content: "No content available" };
                }
    
                // Assuming the entire keyword string is the content
                const content = kw.keyword;
    
                // Attempt to extract the title (the first sentence before a comma or period)
                const titleMatch = content.match(/^(.*?)[,.]/);
                const title = titleMatch ? titleMatch[1] : "Title Not Found";
    
                return { title, content };
            });
    
            setCurrentKeywords(parsedKeywords);
            setShowModal(true);
        } catch (error) {
            console.error("Error parsing keywords:", error);
        }
    };
    

    const toggleAccordionItem = (index) => {
        if (openedAccordionIndex === index) {
            setOpenedAccordionIndex(null); 
        } else {
            setOpenedAccordionIndex(index); 
        }
    };
    

    const handleCloseModal = () => {
        setOpenedAccordionIndex(0); 
        setShowModal(false);
    };
 // useEffect for showButton
 useEffect(() => {
    setShowButton(selectedProduct.length > 0);
    
}, [selectedProduct]);
//all keywords export
const exportKeywords = async () => {
    try {
        const response = await axios.get(`${Helpers.apiUrl}products/keywords-export`, {
            ...Helpers.authHeaders,
            responseType: 'blob' // Important for file downloads
        });
        if (!response) {
            Helpers.toast("error", 'Please select a file to upload');
            return;
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'keywords.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        console.error("Error during export:", error);
    }
};
// export by product id
const exportKeywordsByProductId = async (productId) => {
    try {
        const response = await axios.get(`${Helpers.apiUrl}products/keywords-export/${productId}`, {
            ...Helpers.authHeaders,
            responseType: 'blob' // Important for file downloads
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `keywords_product_${productId}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        console.error("Error during export:", error);
    }
};
const handleExportSelectedProducts = async () => {
    if (selectedProduct.length > 0) {
        setIsExporting(true); // Start exporting and hide the button
        try {
            await exportSelectedKeywords(selectedProduct);
            // Handle any post-export logic here, like showing a success message
        } catch (error) {
            console.error("Error during export:", error);
            // Handle errors, e.g., show an error message to the user
        } finally {
            setIsExporting(false); // Exporting finished, show the button again
        }
    } else {
        console.log("No products selected for export");
    }
};

// export selected keywords
const exportSelectedKeywords = async (selectedProductIds) => {
    // Convert selectedProductIds from strings to integers
    const productIdsAsIntegers = selectedProductIds.map(id => parseInt(id, 10));

    try {
        const response = await axios.post(`${Helpers.apiUrl}products/keywords-export-selected`, 
            { productIds: productIdsAsIntegers }, // sending the converted product IDs
            {
                ...Helpers.authHeaders,
                responseType: 'blob' // Important for file downloads
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'keywords_selected_products.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Optionally, display a success message to the user
    } catch (error) {
        console.error("Error during export:", error);
        // Handle errors, e.g., show an error message to the user
    }
};




useEffect(() => {
    const allKeywordsGenerated = generatedKeywordsCount === selectedProduct.length;

    setShowButton(selectedProduct.length > 0 && !allKeywordsGenerated);
}, [generatedKeywordsCount, selectedProduct]);
    const handlePageClick = (data) => {
        let selected = data.selected;
        setCurrentPage(selected + 1);
        const newPaginatedProducts = Helpers.paginate(allProducts, pageSize)[selected];
        setPaginatedProducts(newPaginatedProducts);
    };

    // Function to handle search input changes


    const renderPagination = () => {
        return (
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={totalPages}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-center'}
                activeClassName={'active'}
                forcePage={currentPage - 1}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                nextClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
            />
        );
    };

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
             
                        {activeTab === 'view' && !isLoading && (
                            <div className="card mt-4" >
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h4 className="card-title">Product List</h4>
                                    <div>
                                          
                                    {showButton && !isExporting && (
                                        <button 
                                            onClick={handleExportSelectedProducts} 
                                            disabled={selectedProduct.length === 0}
                                            className="btn btn-warning btn-sm selectedBtn"
                                        >
                                            Export Selected Products
                                        </button>
                                    )}


                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                            {isGeneratingIndividual && (
                                                <div className="spinner-border text-primary mr-2" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )}
                                          
                                            <button
                                                className="btn btn-secondary"
                                                onClick={exportKeywords}
                                            >
                                                Export All Keywords
                                            </button>
                                        </div>
                              
                                </div>
                                <div className="card-body" >
                                    {error && <p className="text-danger">{error}</p>}
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            {/* <tr>
                                            <label className='form-label fw-bolder '>Filter :</label>
                                                    <th>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value='segment_title'
                                                            checked={selectedSearches === 'segment_title'}
                                                            onChange={handleSelectedCheckBox}
                                                        />
                                                        <label className="form-check-label mr-2 mb-3">Segment Title</label>
                                                    </th>
                                                    <th>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value='family_title'
                                                            checked={selectedSearches === 'family_title'}
                                                            onChange={handleSelectedCheckBox}
                                                        />
                                                        <label className="form-check-label mr-2  mb-3">Family Title</label>
                                                    </th>
                                                    <th>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value='class_title'
                                                            checked={selectedSearches === 'class_title'}
                                                            onChange={handleSelectedCheckBox}
                                                        />
                                                        <label className="form-check-label mr-2  mb-3">Class Title</label>
                                                    </th>
                                                    <th>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value='commodity_title'
                                                            checked={selectedSearches === 'commodity_title'}
                                                            onChange={handleSelectedCheckBox}
                                                        />
                                                        <label className="form-check-label mr-2  mb-3">Commodity Title</label>
                                                    </th>
                                                </tr> */}
                                                <tr>
                                                    <th>Select</th>
                                                    {/* <th>Key</th>
                                                    <th>Segment</th> */}
                                                    <th>Segment Title</th>
                                                    {/* <th>Family</th> */}
                                                    <th>Family Title</th>
                                                    {/* <th>Class</th> */}
                                                    <th>Class Title</th>
                                                    {/* <th>Commodity</th> */}
                                                    <th>Commodity Title</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedProducts.length > 0 ? (
                                                    paginatedProducts.map((product, index) => (
                                                        <tr key={product.id}>
                                                                {
                                                                (
                                                                    <td className="center-both">
                                                                        <input
                                                                            onClick={handleProductSelection}
                                                                            type="checkbox"
                                                                            className="form-check-input align-middle"
                                                                            value={product.id}></input></td>
                                                                )}
                                                     
                                                            {/* <td>{product.key}</td>
                                                            <td>{product.segment}</td> */}
                                                            <td>{product.segment_title}</td>
                                                            {/* <td>{product.family}</td> */}
                                                            <td>{product.family_title}</td>
                                                            {/* <td>{product.class}</td> */}
                                                            <td>{product.class_title}</td>
                                                            {/* <td>{product.commodity}</td> */}
                                                            <td>{product.commodity_title}</td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                {product.keywords && product.keywords.length > 0 ? (
                                                               <div style={{ display: 'block' }}>
                                                                    <button
                                                                        key={forceState}
                                                                        className="btn btn-info btn-sm"
                                                                        onClick={() => handleViewKeywords(product.keywords)}
                                                                        style={{ display: 'block', width: '80%', marginBottom: '6px' }} // Added display block and margin-bottom
                                                                    >
                                                                        View Keywords
                                                                    </button>
                                                                    {/* <button
                                                                        className="btn btn-warning btn-sm"
                                                                        onClick={() => generateKeywordsForSpecificProduct(product.id)}
                                                                        disabled={isGeneratingAll}
                                                                        style={{ display: 'block', width: '80%', whiteSpace: 'normal' }} // Changed to display block
                                                                        title="Generate Keyword"
                                                                    >
                                                                        {isGeneratingSpecific[product.id] ? 'Generating...' : 'Generate'}
                                                                    </button> */}
                                                                    <button
                                                                        className="btn btn-success btn-sm"
                                                                        onClick={() => exportKeywordsByProductId(product.id)}
                                                                        style={{ display: 'block', width: '80%', marginTop: '6px' }}
                                                                    >
                                                                        Export Keywords
                                                                    </button>
                                                                </div>

                                                                ) 
                                                                : (
                                                                    <button
                                                                        className="btn btn-warning btn-sm"
                                                                        onClick={() => generateKeywordsForSpecificProduct(product.id)}
                                                                        disabled={isGeneratingAll}
                                                                        style={{ display: 'block', marginTop: '6px', width: '80%', whiteSpace: 'normal' }}
                                                                        title="Generate Keyword"
                                                                    >
                                                                        {isGeneratingSpecific[product.id] ? 'Generating...' : 'Generate'}
                                                                    </button>
                                                                )
                                                                }
                                                            </td>


                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle', height: '100px' }}>
                                                            No products found.
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>

                                        </table>
                                    </div>
                                    {renderPagination()}
                                </div>
                            </div>
                        )}
                    </div>
       
                </div>
            </div>
            {/* ye show model */}
            {showModal && (
                <div className="modal show" tabIndex="-1">
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Keywords</h5>
                                <button type="button" className="close" aria-label="Close" onClick={handleCloseModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body keyword-list">
                            {currentKeywords.map((keyword, index) => (
                                <div key={index}>
                                    <button
                                        className={`btn btn-primary color-white ${openedAccordionIndex !== index ? 'collapsed' : ''}`}
                                        onClick={() => toggleAccordionItem(index)}
                                    >
                                        <span className={openedAccordionIndex === index ? 'activeButtonContent' : ''}>
                                            {`Article ${index + 1} ${openedAccordionIndex === index ? '▲' : '▼'}`}
                                        </span>
                                    </button>
                                    {openedAccordionIndex === index && (
                                        <div className="accordion-content">
                                            {keyword.content}
                                            <button
                                                className='badge bg-primary'
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent accordion from toggling
                                                    copyToClipboard(keyword.content, index);
                                                }}
                                            >
                                                Copy
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))}


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={handleCloseModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {isLoading && (
                <div className="loader-wrapper">
                    <div className="spinner"></div>
                </div>
            )}
        </div>
    );
};

export default ProductsComponent;
