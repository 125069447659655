import React, { useState, useEffect } from 'react';

const CreatePromptForm = ({ onSubmit, initialPrompt = '' }) => {
  const [prompt, setPrompt] = useState('');

  useEffect(() => {
    if (initialPrompt) {
      setPrompt(initialPrompt);
    }
  }, [initialPrompt]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(prompt);
    setPrompt('');
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="prompt">Your Prompt:</label>
          <textarea
            className="form-control"
            id="prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          {initialPrompt ? 'Update' : 'Create'}
        </button>
      </form>
    </div>
  );
};

export default CreatePromptForm;
