const ChatGPTFormatter = ({ response, writing = false }) => {
    // Function to format response
    const formatResponse = (text) => {
        if (text) {
            // Replace Markdown bold and italic formatting
            let formattedText = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
            
            // Replace --- with a horizontal rule
            formattedText = formattedText.replace(/---/g, '<hr>');
            
            // Replace newlines with HTML break
            return formattedText.replace(/\n/g, '<br/>');
        } else {
            return "";
        }
    };
    
  
    return (
      <div className="chat-gpt-formatter">
        <span dangerouslySetInnerHTML={{ __html: formatResponse(response) }} />
        {writing && <span className="cursor">|</span>}
      </div>
    );
  };
  
export default ChatGPTFormatter;