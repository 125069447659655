import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Helpers from '../../../Config/Helpers';
import '../Screens/Loader.css';
import ReactPaginate from 'react-paginate';
import { FaCheckCircle } from "react-icons/fa";
const pageSize = 10;

const ProductsComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [productID, setProductID] = useState(null);
    const [isGeneratingIndividual, setIsGeneratingIndividual] = useState(false);
    const [isGeneratingSpecific, setIsGeneratingSpecific] = useState({});
    const [paginatedProducts, setPaginatedProducts] = useState([]);
    const [selectedSearches, setSelectedSearches] = useState(["class_title"]);

    const [totalPages, setTotalPages] = useState(0);
    const [showButton, setShowButton] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isGeneratingAll, setIsGeneratingAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('view');
    const [error, setError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [forceState, setForceState] = useState(0);
    const [searchedObject, setSearchedObject] = useState({});
    const [generatedKeywordsCount, setGeneratedKeywordsCount] = useState(0);
    const [isAnyGenerating, setIsAnyGenerating] = useState(false);
    const [keywordGenerationCompleted, setKeywordGenerationCompleted] = useState(false);

    useEffect(() => {
        setForceState(prev => prev + 1)
        // console.log(allProducts);
       
    }, [allProducts])
    const fetchProducts = async (page) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${Helpers.apiUrl}products/getRecentData`, Helpers.authHeaders);
            const fetchedProducts = response.data || [];
            setAllProducts(fetchedProducts);
            setPaginatedProducts(Helpers.paginate(fetchedProducts, pageSize)[currentPage - 1] || []);
            setTotalPages(Math.ceil(fetchedProducts.length / pageSize));
            Helpers.toast("success", 'Product Fetch Successfully');
        } catch (error) {
            setError('Error fetching products: ' + error.message);
            console.error('Error fetching products:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedSearches.length === 0) {
            Helpers.toast("error", 'Please select at least one checkbox for filter!');
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post(`${Helpers.apiUrl}products/uploadRecentData`, formData, Helpers.authFileHeaders);
            // console.log(response.data);
            Helpers.toast("success", 'Products uploaded successfully!');
            fetchProducts(1);
            setActiveTab('view');
        } catch (error) {
            // console.log(error.response.data);
            Helpers.toast("error", 'Failed to upload products.');
        } finally {
            setIsLoading(false);
            setSelectedFile(null);
        }
    };


      

    const handleSelectedCheckBox = event => {
        const value = event.target.value;
            if(!value){
    
                Helpers.toast("error", 'Plaese Select Checkbox for Filter!');
                return;
            }
        setSelectedSearches(currentSelected => {

            if (currentSelected === value) {
                
                return '';
            } else {
                // Otherwise, select the new checkbox
                return value;
            }
        });
    };

    const handleCheckboxChange = event => {
        const value = event.target.value;
        setSelectedSearches(currentSelected => {
            const newSelected = Array.isArray(currentSelected) ? currentSelected : [];
            if (newSelected.includes(value)) {
                return newSelected.filter(item => item !== value);
            } else {
                return [...newSelected, value];
            }
        });
    };
      
    
    
      const generateKeywordsForSpecificProduct = async (productId) => {
        setIsGeneratingSpecific(prevState => ({
          ...prevState,
          [productId]: true,
        }));
      
        if (!selectedSearches || selectedSearches.length === 0) {
          Helpers.toast("error", 'Please select at least one checkbox for filter!');
          setIsGeneratingSpecific(prevState => ({
            ...prevState,
            [productId]: false,
          }));
          return;
        }
      
        const productIndex = allProducts.findIndex(product => product.id === productId);
        if (productIndex === -1) {
          console.error(`Product with ID ${productId} not found.`);
          setIsGeneratingSpecific(prevState => ({
            ...prevState,
            [productId]: false,
          }));
          return;
        }
      
        setIsGeneratingAll(false);
        const product = allProducts[productIndex];
      
        const shouldGenerateClassTitle = selectedSearches.includes('class_title') && product.class_title !== null;
        const shouldGenerateCommodityTitle = selectedSearches.includes('commodity_title') && product.commodity_title !== null;
        const shouldGenerateSegmentTitle = selectedSearches.includes('segment_title') && product.segment_title !== null;
        const shouldGenerateFamilyTitle = selectedSearches.includes('family_title') && product.family_title !== null;
      
        // Check if at least one condition is met
        if (
          shouldGenerateClassTitle ||
          shouldGenerateCommodityTitle ||
          shouldGenerateSegmentTitle ||
          shouldGenerateFamilyTitle
        ) {
          // Generate keyword based on available titles
          const selectedOptions = [];
          if (shouldGenerateClassTitle) selectedOptions.push('class_title');
          if (shouldGenerateCommodityTitle) selectedOptions.push('commodity_title');
          if (shouldGenerateSegmentTitle) selectedOptions.push('segment_title');
          if (shouldGenerateFamilyTitle) selectedOptions.push('family_title');
      
          try {
            const response = await axios.post(`${Helpers.apiUrl}products/generateRecentKeywords/${productId}`, {
              productContent: product,
              selectedOptions: selectedOptions,
            }, Helpers.authHeaders);
      
            Helpers.toast("success", 'Keyword Generated Successfully!');
          } catch (error) {
            // console.log('ab wala chala h');
            console.error(`Error generating keywords for product ID ${productId}:`, error);
          } finally {
     
            setIsGeneratingSpecific(prevState => ({
              ...prevState,
              [productId]: false,
            }));
            setKeywordGenerationCompleted(true);
            fetchProducts(currentPage);
          }
        } else {
            Helpers.toast('error',`Selected Product Value is null`);

          setIsGeneratingSpecific(prevState => ({
            ...prevState,
            [productId]: false,
          }));
        }
      };
      
      useEffect(() => {
        setShowButton(selectedProduct.length > 0 && !keywordGenerationCompleted);
    }, [selectedProduct, keywordGenerationCompleted]);
    
    const handleProductSelection = event => {
        const value = event.target.value;
        const checked = event.target.checked; // This is true if the checkbox is checked

        setSelectedProduct(prevSelectedProduct => {
            if (checked) {
                return prevSelectedProduct.includes(value) ? prevSelectedProduct : [...prevSelectedProduct, value];
            } else {

                return prevSelectedProduct.filter(item => item !== value);
            }
        });
    };
    const isAnyProductGenerating = selectedProduct.some(productId => isGeneratingSpecific[productId]);
    const buttonText = isAnyProductGenerating ? 'Generating...' : 'Generate Selected Keywords';
 

    const handleSearchMultipleSelected = async () => {
        setIsAnyGenerating(true);
        setKeywordGenerationCompleted(false);
    
        for (const productId of selectedProduct) {
            let product = allProducts.find(item => item.id.toString() === productId.toString());
            if (product) {
                const updatedSearchedObject = await sortMultipleSelected(product);
                try {
                    await generateKeywordsForSpecificProduct(product.id, updatedSearchedObject);
                    // console.log(`Keywords generated for product ID ${productId}`);
                } catch (error) {
                    // console.log('ye wala chala');
                    console.error(`Error generating keywords for product ID ${productId}:`, error);
                }
            } else {
                // console.error(`Product with ID ${productId} not found.`);
            }
        }
    
        setIsAnyGenerating(false);
        setKeywordGenerationCompleted(true);
        setShowButton(false); // Hide the button only after all products are processed
        // console.log("All products processed for keyword generation.");
    };
    
    
    
    
    

    const sortMultipleSelected = async (product) => {
        let updatedSearchedObject = { ...searchedObject };
      
        if (Array.isArray(selectedSearches)) {
          selectedSearches.forEach(element => {
            updatedSearchedObject[element] = product[element];
          });
        } else {
          console.error('selectedSearches is not an array:', selectedSearches);
        }
      
        return updatedSearchedObject;
      };
  
    const handleSearchSelected = (input) => {
        setIsAnyGenerating(true);
        setKeywordGenerationCompleted(false);
        let productObject;
        let productId;
      
        if (typeof input === 'object') {
          productObject = input;
          productId = productObject.id;
        } else {
          productId = parseInt(input, 10);
          productObject = allProducts.find(product => product.id === productId);
      
          if (!productObject) {
            return;
          }
        }
      
        let updatedSearchedObject = { ...searchedObject };
      
        // Ensure selectedSearches is an array before iterating through it
        const selectedSearchesArray = Array.isArray(selectedSearches) ? selectedSearches : [selectedSearches];
      
        selectedSearchesArray.forEach(element => {
          updatedSearchedObject[element] = productObject[element];
        });
      
        setSearchedObject(updatedSearchedObject);
        setProductID(productId);
  
      };
      

    useEffect(() => {
        setIsAnyGenerating(false);
        generateKeywordsForSpecificProduct(productID, searchedObject);
    }, [searchedObject]);

    const toggleKeywordGeneration = () => {
        // alert('yes')
        if (isGeneratingIndividual) {
            setIsGeneratingIndividual(false);
        } else {
            // console.log('Initiating keyword generation for all products.');
            generateAllKeywords();
        }
    };
   
  
    const generateAllKeywords = async () => {
        setIsGeneratingIndividual(true); // Start generating
        setError(null);
      
        // console.log("Selected Searches:", selectedSearches);
      
        if (selectedSearches.length === 0) {
          Helpers.toast("error", 'Please select at least one checkbox for filter!');
          setIsGeneratingIndividual(false); // Stop the generating process
          return;
        }
      
        try {
          const updatedProducts = await Promise.all(allProducts.map(async (product) => {
            if (product.generatedKeyword) {
              return product;
            }
      
            // Check for null values in selected properties
            let shouldSkip = true;
            selectedSearches.forEach(search => {
              switch (search) {
                case 'class_title':
                  if (product.class_title !== null) {
                    shouldSkip = false;
                  }
                  break;
                case 'commodity_title':
                  if (product.commodity_title !== null) {
                    shouldSkip = false;
                  }
                  break;
                case 'segment_title':
                  if (product.segment_title !== null) {
                    shouldSkip = false;
                  }
                  break;
                case 'family_title':
                  if (product.family_title !== null) {
                    shouldSkip = false;
                  }
                  break;
                default:
                  break;
              }
            });
      
            if (shouldSkip) {
              // Skip this product
              Helpers.toast('error',`Product ID ${product.id} skipped due to null or undefined values in selected properties.`);
              return product;
            }
      
            try {
              const response = await axios.post(`${Helpers.apiUrl}products/generateRecentKeywords/${product.id}`, {
                productContent: product,
                selectedOptions: selectedSearches
              }, Helpers.authHeaders);
      
              return { ...product, generatedKeyword: response.data.keyword, keywords: response.data.keywords };
            } catch (error) {
                // console.log('all wala chala');
              console.error(`Error generating keywords for product ID ${product.id}:`, error);
              return product; // Return the product as is if there was an error
            }
          }));
      
          setAllProducts(updatedProducts);
          setShowButton(false);
          Helpers.toast("success", 'All Keywords Generated Successfully!');
        } catch (error) {
          console.error('Failed to generate keywords:', error);
          setError(error); // Store the error in the state
          Helpers.toast("error", 'Failed to generate all keywords.');
        } finally {
          if (!error) {
            // If there's no error, proceed with these actions
            fetchProducts(currentPage);
            setIsGeneratingIndividual(false); // Finish the generating process
          }
        }
      };
      
    
    

    useEffect(() => {
        // Function to update paginated products
        const updatePagination = () => {
            const paginated = Helpers.paginate(allProducts, pageSize);
            setPaginatedProducts(paginated[currentPage - 1] || []);
            setTotalPages(Math.ceil(allProducts.length / pageSize));
        };
    
        updatePagination();
    }, [allProducts]); // Add pageSize to dependencies if it can change



    useEffect(() => {
        const fetchAllProducts = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${Helpers.apiUrl}products/getRecentData`, Helpers.authHeaders);
                const fetchedProducts = response.data || [];
                const paginated = Helpers.paginate(fetchedProducts, pageSize);
                setAllProducts(fetchedProducts);
                setTotalPages(Math.ceil(fetchedProducts.length / pageSize));
                setPaginatedProducts(paginated[currentPage - 1] || []);
                Helpers.toast("success", 'Products fetched successfully!');
            } catch (error) {
                setError('Failed to fetch products');
                console.error('Error fetching products:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAllProducts();
    }, [currentPage]);

    
 
 // useEffect for showButton



useEffect(() => {
    const allKeywordsGenerated = generatedKeywordsCount === selectedProduct.length;
    setShowButton(selectedProduct.length > 0 && !allKeywordsGenerated);
}, [generatedKeywordsCount, selectedProduct]);
    const handlePageClick = (data) => {
        let selected = data.selected;
        setCurrentPage(selected + 1);
        const newPaginatedProducts = Helpers.paginate(allProducts, pageSize)[selected];
        setPaginatedProducts(newPaginatedProducts);
    };


    const renderPagination = () => {
        return (
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={totalPages}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-center'}
                activeClassName={'active'}
                forcePage={currentPage - 1}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                nextClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
            />
        );
    };

    return (
        <div className="nk-content">
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
        
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'view' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('view')}
                                >
                                    View Products
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'upload' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('upload')}
                                >
                                    Upload Products
                                </button>
                            </li>
                        </ul>
                        {activeTab === 'upload' && (
                            <div className="card mt-4">
                                <div className="card-header">
                                    <h4 className="card-title">Upload New Products</h4>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit} className="form">
                                        <div className="mb-3">
                                            <label htmlFor="file-upload" className="form-label">
                                                Upload Products File (Excel Format)
                                            </label>
                                            <input
                                                type="file"
                                                id="file-upload"
                                                className="form-control"
                                                onChange={handleFileChange}
                                                disabled={isLoading}
                                            />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                {isLoading ? 'Uploading...' : 'Start Uploading'}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() => setActiveTab('view')}
                                                disabled={isLoading}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}
                        {activeTab === 'view' && !isLoading && (
                            <div className="card mt-4" >
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <h4 className="card-title">Product List</h4>
                                    <div>
                                    {showButton && (
                                        <button
                                            className="btn btn-warning btn-sm selectedBtn"
                                            onClick={() => {
                                                if (selectedProduct.length > 1) {
                                                    handleSearchMultipleSelected();
                                                } else {
                                                    const singleProductId = selectedProduct[0];
                                                    handleSearchSelected(singleProductId);
                                                }
                                            }}
                                            disabled={isAnyGenerating || isGeneratingAll}
                                        >
                                            {buttonText}
                                        </button>
                                    )}

                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                            {isGeneratingIndividual && (
                                                <div className="spinner-border text-primary mr-2" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            )}
                                            <button
                                                className="btn btn-primary mr-2"
                                                onClick={toggleKeywordGeneration}
                                                disabled={isGeneratingIndividual}
                                            >
                                                {isGeneratingIndividual ? 'Generating...' : 'Generate All Keywords'}
                                            </button>

                                        </div>
                              
                                </div>
                                <div className="card-body" >
                                    {error && <p className="text-danger">{error}</p>}
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                             <label className='form-label fw-bolder '>Filter :</label>
                                                <th>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="segment_title"  
                                                    value='segment_title'
                                                    checked={selectedSearches.includes('segment_title')}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="form-check-label mr-2 mb-3" htmlFor="segment_title">
                                                    Segment Title
                                                </label>
                                                </th>

                                                    <th>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value='family_title'
                                                        id="family_title" 
                                                        checked={selectedSearches.includes('family_title')}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                        <label className="form-check-label mr-2 mb-3" htmlFor="family_title"  >Family Title</label>
                                                    </th>
                                                    <th>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value='class_title'
                                                        id="class_title" 
                                                        checked={selectedSearches.includes('class_title')}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                        <label className="form-check-label mr-2 mb-3" htmlFor="class_title"  >Class Title</label>
                                                    </th>
                                                    <th>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value='commodity_title'
                                                        id="commodity_title" 
                                                        checked={selectedSearches.includes('commodity_title')}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                        <label className="form-check-label mr-2 mb-3" htmlFor="commodity_title"  >Commodity Title</label>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>Select</th>
                                                    {/* <th>Key</th>
                                                    <th>Segment</th> */}
                                                    <th>Segment Title</th>
                                                    {/* <th>Family</th> */}
                                                    <th>Family Title</th>
                                                    {/* <th>Class</th> */}
                                                    <th>Class Title</th>
                                                    {/* <th>Commodity</th> */}
                                                    <th>Commodity Title</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedProducts.length > 0 ? (
                                                    paginatedProducts.map((product, index) => (
                                                        <tr key={product.id}>
                                                                {product.keywords && product.keywords.length > 0 ? (
                                                                    <td>
                                                                        <FaCheckCircle />
                                                                    </td>
                                                                ) : (
                                                                    <td className="center-both">
                                                                        <input
                                                                            onClick={handleProductSelection}
                                                                            type="checkbox"
                                                                            className="form-check-input align-middle"
                                                                            value={product.id}
                                                                           
                                                                            >
                                                                            </input></td>
                                                                            
                                                                )}
                                                     
                                                            {/* <td>{product.key}</td>
                                                            <td>{product.segment}</td> */}
                                                            <td>{product.segment_title}</td>
                                                            {/* <td>{product.family}</td> */}
                                                            <td>{product.family_title}</td>
                                                            {/* <td>{product.class}</td> */}
                                                            <td>{product.class_title}</td>
                                                            {/* <td>{product.commodity}</td> */}
                                                            <td>{product.commodity_title}</td>
                                                      
                                                            <td>
                                                            
                                                            <button
                                                                     className="btn btn-warning btn-sm"
                                                                     onClick={() => generateKeywordsForSpecificProduct(product.id)}
                                                                     disabled={isGeneratingIndividual || isGeneratingSpecific[product.id]}
                                                                     style={{ display: 'block', width: '80%', whiteSpace: 'normal' }}
                                                                     title="Generate Keyword"
                                                                 >
                                                                     {isGeneratingSpecific[product.id] ? 'Generating...' : 'Generate'}
                                                                 </button>
                                                         </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="10" style={{ textAlign: 'center', verticalAlign: 'middle', height: '100px' }}>
                                                            No products found.
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>

                                        </table>
                                    </div>
                                    {renderPagination()}
                                </div>
                            </div>
                        )}
                    </div>
       
                </div>
            </div>
        


            {isLoading && (
                <div className="loader-wrapper">
                    <div className="spinner"></div>
                </div>
            )}
        </div>
    );
};

export default ProductsComponent;
