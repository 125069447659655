import { Link } from "react-router-dom";

const Home = () => {

    return (
        <main class="nk-pages">
            <section class="section section-0 has-shape bg-primary mt-100">
                <div class="container">
                    <div class="section-content pb-75">
                        <div class="row text-center g-gs justify-content-center">
                            <div class="col-md-6 col-xl-4">
                                <div class="pt-6 h-100">
                                    <div class="card h-100 rounded-4 shadow-sm stat-card">
                                        <div class="card-body">
                                            <div class="feature">
                                                <div class="feature-media mt-n8 mb-3">
                                                    <div class="media media-3xl media-middle media-border text-bg-primary-soft-outline rounded-4">
                                                        <img src="/assets/app/time.png" className="w50" alt="" />
                                                    </div>
                                                </div>
                                                <div class="feature-text">
                                                    <h4 class="title fz-50 text-primary">72 Hours</h4>
                                                    <p className="text-primary">Time saved per week on average</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="pt-6 h-100">
                                    <div class="card h-100 rounded-4 stat-card shadow-sm">
                                        <div class="card-body">
                                            <div class="feature">
                                                <div class="feature-media mt-n8 mb-3">
                                                    <div class="media media-3xl media-middle media-border text-bg-primary-soft-outline rounded-4">
                                                        <img src="/assets/app/save-money.png" className="w50" alt="" />
                                                    </div>
                                                </div>
                                                <div class="feature-text">
                                                    <h4 class="title text-primary fz-50">$35,000/yr</h4>
                                                    <p className="text-primary">Money saved on copywriting expenses</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="pt-6 h-100">
                                    <div class="card h-100 rounded-4 stat-card shadow-sm">
                                        <div class="card-body">
                                            <div class="feature">
                                                <div class="feature-media mt-n8 mb-3">
                                                    <div class="media media-3xl media-middle media-border text-bg-primary-soft-outline rounded-4">
                                                        <img src="/assets/app/rush.png" className="w50" alt="" />
                                                    </div>
                                                </div>
                                                <div class="feature-text">
                                                    <h4 class="title text-primary fz-50">10X - 100X</h4>
                                                    <p className="text-primary">Faster than the average writer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section-lg section-bottom-0 has-shape">
                <div class="nk-shape bg-shape-border-d mt-n8 mt-lg-n9 start-50 translate-middle-x"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-9 col-xl-7">
                                <h2 class="title">Content generation made easy</h2>
                                <p class="lead">Give our AI a few descriptions and we'll automatically create blog articles, product descriptions and more for you within just few second.</p>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row g-gs">
                            <div class="col-lg-4">
                                <div class="card bg-gradient-light border-0 h-100">
                                    <div class="card-body p-3 d-flex">
                                        <div class="feature feature-inline align-items-center">
                                            <div class="feature-text me-auto">
                                                <h4 class="title">Trustworthy Research</h4>
                                                <p>Reliable, fact-based information at your fingertips. Trustworthy research you can depend on for informed decision-making and accurate insights.</p>
                                            </div>
                                            <div class="feature-decoration flex-shrink-0 ms-4 me-n3"><img src="images/number/1-light.png" alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card bg-gradient-light border-0 h-100">
                                    <div class="card-body p-3 d-flex">
                                        <div class="feature feature-inline align-items-center">
                                            <div class="feature-text me-auto">
                                                <h4 class="title">Time Efficiency</h4>
                                                <p>Maximize productivity with our time-efficient solutions. Streamline tasks, save valuable minutes, and achieve more in less time.</p>
                                            </div>
                                            <div class="feature-decoration flex-shrink-0 ms-4 me-n3"><img src="images/number/2-light.png" alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card bg-gradient-light border-0 h-100">
                                    <div class="card-body p-3 d-flex">
                                        <div class="feature feature-inline align-items-center">
                                            <div class="feature-text me-auto">
                                                <h4 class="title">SEO Efficiency</h4>
                                                <p>Boost your online presence effortlessly. Our SEO efficiency tools enhance rankings and drive organic traffic to your website.</p>
                                            </div>
                                            <div class="feature-decoration flex-shrink-0 ms-4 me-n3"><img src="images/number/3-light.png" alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="features-section" class="section section-lg section-bottom-0">
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-lg-10 col-xl-9 col-xxl-8">
                                <h2 class="title h2">Unleash the power of SEO Products</h2>
                                <p class="lead">Unlock the Potential of SEO Products: Drive sales, engage customers, and grow your e-commerce business with our potent email marketing strategies!</p>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row gy-gs gx-xxl-9">
                            <div class="col-md-6">
                                <div class="feature feature-inline">
                                    <div class="feature-media">
                                        <div class="text-gradient-primary fs-2 d-inline-flex"><em class="icon ni ni-home-alt"></em></div>
                                    </div>
                                    <div class="feature-text">
                                        <h4 class="title">Home Page Prompts</h4>
                                        <p>You can generate high-quality content in seconds, saving you valuable time that you can spend on other important tasks.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="feature feature-inline">
                                    <div class="feature-media">
                                        <div class="text-gradient-primary fs-2 d-inline-flex"><em class="icon ni ni-bulb"></em></div>
                                    </div>
                                    <div class="feature-text">
                                        <h4 class="title">Upsell Prompts</h4>
                                        <p>An interview-style show featuring industry leaders, experts, and influencers, covering topics ranging from tech, politics, art, and more.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="feature feature-inline">
                                    <div class="feature-media">
                                        <div class="text-gradient-primary fs-2 d-inline-flex"><em class="icon ni ni-activity-round"></em></div>
                                    </div>
                                    <div class="feature-text">
                                        <h4 class="title">Subscription Prompts</h4>
                                        <p>Use templates to streamline your content creation process. This can include templates for blog posts, social media posts, videos, and more.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="feature feature-inline">
                                    <div class="feature-media">
                                        <div class="text-gradient-primary fs-2 d-inline-flex"><em class="icon ni ni-layout-alt"></em></div>
                                    </div>
                                    <div class="feature-text">
                                        <h4 class="title">Landing Page Prompts</h4>
                                        <p>Reading widely can help improve your writing skills by exposing you to different writing styles, grammar structures, and vocabulary.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="feature feature-inline">
                                    <div class="feature-media">
                                        <div class="text-gradient-primary fs-2 d-inline-flex"><em class="icon ni ni-cart"></em></div>
                                    </div>
                                    <div class="feature-text">
                                        <h4 class="title">Checkout Page Prompts</h4>
                                        <p>Copygen can read and write content in 25 languages including English, Spanish, Japanese, and Portuguese.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="feature feature-inline">
                                    <div class="feature-media">
                                        <div class="text-gradient-primary fs-2 d-inline-flex"><em class="icon ni ni-facebook-circle"></em></div>
                                    </div>
                                    <div class="feature-text">
                                        <h4 class="title">Facebook Retargeting Prompts</h4>
                                        <p>Building an AI-powered business is an ongoing process, and requires ongoing investment in data, technology, and expertise.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="feature feature-inline">
                                    <div class="feature-media">
                                        <div class="text-gradient-primary fs-2 d-inline-flex"><em class="icon ni ni-mail"></em></div>
                                    </div>
                                    <div class="feature-text">
                                        <h4 class="title">Daily Email Prompts</h4>
                                        <p>Clear communication is key to successful collaboration. Be sure to express your ideas clearly and listen actively to others.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="feature feature-inline">
                                    <div class="feature-media">
                                        <div class="text-gradient-primary fs-2 d-inline-flex"><em class="icon ni ni-article"></em></div>
                                    </div>
                                    <div class="feature-text">
                                        <h4 class="title">Blog Posts Prompts</h4>
                                        <p>AI is an exciting, new technology that can unlock your imagination to create some amazing things.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section-lg section-bottom-0 has-shape">
                <div class="nk-shape bg-shape-blur-g mt-n20p start-50"></div>
                <div class="nk-shape bg-shape-blur-h translate-middle-y top-50 end-50"></div>
                <div class="nk-shape bg-shape-blur-i mb-n30p bottom-0 start-50"></div>
                <div class="container">
                    <div class="section-head">
                        <div class="row justify-content-center text-center">
                            <div class="col-xl-11 col-xxl-10"><h2 class="title h1">How it works</h2></div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row g-gs justify-content-between justify-content-xl-center flex-md-row-reverse align-items-center">
                            <div class="col-lg-5 col-md-6 offset-xl-1">
                                <div class="block-gfx position-relative">
                                    <svg class="position-absolute top-0 start-0 mt-n5 ms-n3 me-6 z-index-n1" viewBox="0 0 413 367" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M297.773 365.385C240.904 379.987 171.136 291.086 138.891 279.276C75.5722 256.299 -32.89 263.6 9.61542 210.775C29.2559 186.295 70.2956 153.011 85.539 133.9C116.612 95.0327 55.6386 25.4583 110.749 5.05841C165.566 -15.1267 212.176 30.612 266.114 45.6435C301.584 55.736 339.692 50.7971 376.042 55.9508C400.959 59.6013 422.944 80.0012 408.287 99.9716C318.293 222.8 394.216 340.476 297.773 365.385Z"
                                            fill="#B4EBFC"
                                        />
                                    </svg>
                                    <img class="w-100 rounded-3 shadow-sm" src="/assets/app/process/a.jpg" alt="" />
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-6">
                                <div class="block-text">
                                    <h6 class="text-primary">01</h6>
                                    <h3 class="title">Select a Template</h3>
                                    <p>Easily choose a template that suits your needs to kickstart the content creation process with simplicity and convenience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row g-gs justify-content-between justify-content-xl-center align-items-center">
                            <div class="col-lg-5 col-md-6">
                                <div class="block-gfx">
                                    <svg
                                        class="position-absolute top-0 start-0 mt-n5 mt-lg-n6 mt-xl-n7 ms-2 ms-sm-3 ms-md-0 ms-lg-1 me-5 me-sm-6 me-md-2 me-lg-0 me-xl-2 z-index-n1"
                                        viewBox="0 0 410 408"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M260.99 3.04631L55.1726 57.9406C15.7332 68.4596 -7.67183 108.811 2.89591 148.069L58.0447 352.937C68.6124 392.195 109.151 415.492 148.59 404.973L354.408 350.078C393.848 339.559 417.253 299.208 406.685 259.95L351.536 55.0818C340.968 15.8244 300.43 -7.47268 260.99 3.04631Z"
                                            fill="#B9B3FF"
                                        />
                                    </svg>
                                    <img class="w-100 rounded-3 shadow-sm" src="/assets/app/process/b.jpg" alt="" />
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-6 col-lg-6 offset-xl-1">
                                <div class="block-text">
                                    <h6 class="text-primary">02</h6>
                                    <h3 class="title">Answer Template Questions</h3>
                                    <p>Provide insightful answers to template questions, guiding you through the content generation process effortlessly</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-content">
                        <div class="row g-gs justify-content-between justify-content-xl-center flex-md-row-reverse align-items-center">
                            <div class="col-lg-5 col-md-6 offset-xl-1">
                                <div class="block-gfx">
                                    <svg class="position-absolute top-0 start-0 mt-n5 ms-n5 z-index-n1" viewBox="0 0 452 373" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M448.494 259.068C420.999 316.242 367.226 292.754 321.726 287.81C232.429 277.92 171.844 428.427 78.1669 350.546C-25.4856 264.322 -26.2156 107.634 76.9503 47.6789C365.766 -120.753 473.555 207.148 448.494 259.068Z"
                                            fill="#B9F7F5"
                                        />
                                    </svg>
                                    <img class="w-100 rounded-3 shadow-sm" src="/assets/app/process/c.jpg" alt="" />
                                </div>
                            </div>
                            <div class="col-xl-4 col-md-6">
                                <div class="block-text">
                                    <h6 class="text-primary">03</h6>
                                    <h3 class="title">Generate AI Content</h3>
                                    <p>Harness the power of AI to instantly generate tailored content, simplifying the creative process and saving you time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section section-lg has-shape">
                <div class="nk-shape bg-shape-blur-k end-50 top-0"></div>
                <div class="nk-shape bg-shape-blur-l start-50 top-75"></div>
                <div class="container">
                    <div class="section-content">
                        <div class="row justify-content-center text-center">
                            <div class="col-xl-6 col-lg-8 col-md-10">
                                <div class="block-text">
                                    <h2 class="title h1">Ready to get started?</h2>
                                    <p class="lead mt-3">It's like having access to a team of copywriting experts writing powerful copy for you in 1-click.</p>
                                    <ul class="btn-list btn-list-inline">
                                        <li>
                                            <Link to="/register" class="btn btn-lg btn-primary rounded-pill"><span>Get Started For Free</span><em class="icon ni ni-arrow-long-right"></em></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Home;