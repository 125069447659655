import {BrowserRouter , Routes , Route, Navigate} from 'react-router-dom'
import Home from './App/Screens/Home';
import Layout from './App/Screens/Layout';
import './App.css';
import Login from './App/Screens/Auth/Login';
import Register from './App/Screens/Auth/Register';
import UserLayout from './App/Screens/User/Layout';
import UserDashboard from './App/Screens/User/Screens/Dashboard';
import Verify from './App/Screens/Auth/Verify';
import Helpers from './App/Config/Helpers';
import AdminLayout from './App/Screens/Admin/Layout';
import AdminDashboard from './App/Screens/Admin/Screens/Dashboard';
import ForgotPassword from './App/Screens/Auth/ForgotPassword';
import VerifyForgotPassword from './App/Screens/Auth/VerifyForgot';
import RecoverPassword from './App/Screens/Auth/RecoverPassword';
import UploadProducts from './App/Screens/Admin/Screens/Upload';
import Products from './App/Screens/Admin/Screens/Products';
import RecentProducts from './App/Screens/Admin/Screens/RecentProducts';

import PromptsPage from './App/Screens/Admin/Screens/PromptPage';
import OldProducts from './App/Screens/Admin/Screens/OldProducts';


const Auth = ({children, isAuth = true, isAdmin = false}) => {
  let user = Helpers.getItem("user", true);
  let token = Helpers.getItem("token");
  let loginTime = Helpers.getItem("loginTimestamp");
  let currentTime = new Date().getTime();
  let minutesPassed = Math.floor((currentTime - loginTime) / (1000 * 60));

  // Check for session expiration
  if (loginTime && minutesPassed > 120) {
    localStorage.clear();
    Helpers.toast("error", "Session expired. Login again to continue");
    return <Navigate to="/login" />;
  } 
  // For protected routes
  else if (isAuth) {
    if (!user || !token) {
      Helpers.toast("error", "Please login to continue");
      return <Navigate to="/login" />;
    }

    // Ensure only admins can access admin routes
    if (isAdmin && user.user_type !== 1) {
      Helpers.toast("error", "Access denied. Only admin allowed.");
      return <Navigate to="/user/dashboard" />;
    }

    // Ensure admins cannot access user routes
    if (!isAdmin && user.user_type === 1) {
      Helpers.toast("error", "Access denied. Admins cannot access user routes.");
      return <Navigate to="/admin/dashboard" />;
    }

    return children;
  } 
  // For non-protected routes like /login
  else {
    if (user && token) {
      if (user.user_type === 1) {
        return <Navigate to="/admin/dashboard" />;
      } else {
        return <Navigate to="/user/dashboard" />;
      }
    }
    return children;
  }
}

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Auth isAuth={false}><Login /></Auth>} />
          <Route path='/register' element={<Auth isAuth={false}><Register /></Auth>} />
          <Route path='/verify-email' element={<Auth isAuth={false}><Verify /></Auth>} />
          <Route path='/forgot-password' element={<Auth isAuth={false}><ForgotPassword /></Auth>} />
          <Route path='/verify-email-password' element={<Auth isAuth={false}><VerifyForgotPassword /></Auth>} />
          <Route path='/recover-password' element={<Auth isAuth={false}><RecoverPassword /></Auth>} />
        </Route>
        <Route path='/user' element={<UserLayout />}>
          <Route path='/user/dashboard' element={<Auth><UserDashboard /></Auth>} />
        </Route>
        <Route path='/admin' element={<AdminLayout />}>
          <Route path='/admin/dashboard' element={<Auth isAdmin={true}><AdminDashboard /></Auth>} />
          <Route path='/admin/products' element={<Auth isAdmin={true}><UploadProducts /></Auth>} />
          <Route path='/admin/recent-products' element={<Auth isAdmin={true}><RecentProducts /></Auth>} />
          <Route path='/admin/products' element={<Auth isAdmin={true}><Products /></Auth>} />
          <Route path='/admin/prompt' element={<Auth isAdmin={true}><PromptsPage /></Auth>} />
          <Route path='/admin/old-products' element={<Auth isAdmin={true}><OldProducts /></Auth>} />

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;